<template>
  <b-collapse
    class="custom-collapse"
    :class="{'open': isOpen}"
    :open.sync="isOpen"
    animation="">
    <div
      slot="trigger"
      slot-scope="props"
      class="custom-collapse-header"
      :class="headerClass">
      <div class="toggle-collapse-icon">
        <b-icon
          v-if="!isCourierGroupHeader"
          :icon="props.open ? 'minus' : 'plus'"
          size="is-small"/>
      </div>
      <slot name="header"/>
      <b-icon
        v-if="isCourierGroupHeader"
        :icon="props.open ? 'chevron-down' : 'chevron-right'"
        size="is-small"/>
    </div>
    <slot/>
  </b-collapse>
</template>

<script>
export default {
  name: 'custom-collapse',
  props: {
    headerClass: {
      type: [String, Object],
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
    isCourierGroupHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-collapse {
  .custom-collapse-header {
    display: flex;
    align-items: center;
    padding: $space-xs;
    font-weight: bold;
    cursor: pointer;
    @extend %body;

    &::v-deep > :not(:last-child) {
      padding-right: $space-xs;
    }

    .toggle-collapse-icon {
      padding-right: $space-xxs;
      @extend %small;
    }
  }
}
</style>
